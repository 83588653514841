const TEST_LOCALSTORAGE_KEY = 'TEST_LOCALSTORAGE_KEY_OPENTICKET';

export function hasLocalStorage(): boolean {
    try {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem(TEST_LOCALSTORAGE_KEY, 'yes');
            if (localStorage.getItem(TEST_LOCALSTORAGE_KEY) === 'yes') {
                localStorage.removeItem(TEST_LOCALSTORAGE_KEY);
                return true;
            }
        }

        return false;
    } catch (e) {
        return false;
    }
}
