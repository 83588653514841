import './filters';
import './utils';
import './components';

import Vue from 'vue';
import Component from 'vue-class-component';
import VueNotifications from '@openticket/vue-notifications';
import CustomShopSettingsClient from '@openticket/lib-custom-shop-settings';
import { LogLevel } from '@openticket/sdk-shop';
import Whitelabel from '@openticket/lib-whitelabels';
import VueInput from '@openticket/vue-input';

import VueLocalization, {
    Formatters,
    Parsers,
    VueI18n,
    IVueI18n,
} from '@openticket/vue-localization';

import SmoothScroll from 'smoothscroll-polyfill';
import Style from '@openticket/lib-style';
import VueError from './error';

declare module 'vue/types/vue' {
    interface Vue {
        $notifications: VueNotifications;
        $settings?: CustomShopSettingsClient;

        $whitelabel: Whitelabel;
        $style: Style;

        $localization: VueLocalization;
        $l: Formatters;
        $p: Parsers;

        readonly $i18n: VueI18n & IVueI18n;
        $t: typeof VueI18n.prototype.t;
        $tc: typeof VueI18n.prototype.tc;
        $te: typeof VueI18n.prototype.te;
        $d: typeof VueI18n.prototype.d;
        $n: typeof VueI18n.prototype.n;
    }
}

declare module 'vue/types/options' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ComponentOptions<V extends Vue> {
        i18n?: {
            messages?: VueI18n.LocaleMessages;
            dateTimeFormats?: VueI18n.DateTimeFormats;
            numberFormats?: VueI18n.NumberFormats;
            sharedMessages?: VueI18n.LocaleMessages;
        };
    }
}

declare global {
    interface Window {
        Vue: any;
        CustomShopSettings?: CustomShopSettingsClient;
    }
}

// Add Vue constructor to window
window.Vue = Vue;

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

// Make sure scrollTo is smooth on Safari
SmoothScroll.polyfill();

Vue.use(VueI18n);
Vue.use(VueLocalization);

Vue.use(VueInput);

Vue.use(VueNotifications, {
    singleNotification: true,
    duration: 5000,
});

// Add shop settings SDK if URL is provided
if (process.env.VUE_APP_SHOP_SETTINGS_API_URL) {
    Vue.prototype.$settings = window.CustomShopSettings = Vue.observable(
        new CustomShopSettingsClient()
    );
}

const whitelabel = new Whitelabel();
Vue.prototype.$whitelabel = whitelabel;
whitelabel.loadStylesheet();
whitelabel.loadIconPack();
whitelabel.setFavicon();

const style = new Style({});
Vue.prototype.$style = style;
style.initColorSchemeListener();

Vue.config.errorHandler = (err: Error) => {
    // eslint-disable-next-line no-console
    console.error(err);
    if (Vue.prototype.$shop) {
        Vue.prototype.$shop.log.create(
            LogLevel.Error,
            ['general_error'],
            {},
            null,
            err
        );
    } else if (Vue.prototype.$order) {
        Vue.prototype.$order.logger.log(new VueError('root', err));
    }
};

Vue.config.productionTip = false;

export const i18n: VueI18n = Vue.prototype.$localization.getI18n();
