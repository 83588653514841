























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { BaseMetadataItem, ValidationResponse } from '@openticket/sdk-shop';

export type MetaInputValidator = (
    metadata: BaseMetadataItem,
    mutateErrors?: boolean
) => ValidationResponse;

/**
 * MetaInput is the wrapper component for handling a metadata item
 */
@Component
export default class MetaInput<I> extends Vue {
    @Prop() meta!: BaseMetadataItem;
    @Prop({ default: true }) showName!: boolean;
    @Prop() disabled!: boolean;

    @Ref('input')
    input!: HTMLInputElement;

    // True if this input has been validated with errors at least once
    isInitialized = false;
    localeChangeHandler = 0;

    created(): void {
        // Make the meta object observable to watch errors
        Vue.observable(this.meta);

        // This is to make sure the error values are also translated
        this.meta.item.translateName = this.translatedName;
    }

    mounted(): void {
        this.localeChangeHandler = this.$localization.on(
            'locale-change',
            () => {
                // This is to make sure the error values are also translated
                this.meta.item.translateName = this.translatedName;

                if (this.meta.errors.length) {
                    this.validate(this.meta);
                }
            }
        );
    }

    destroyed(): void {
        this.$localization.off(this.localeChangeHandler);
    }

    onInput(val: I): void {
        if (this.meta.errors.length || this.isInitialized) {
            this.validate(this.meta);
        }

        this.$emit('input', val);
    }

    onBlur(val: I): void {
        this.validate(this.meta);

        this.isInitialized = true;

        this.$emit('blur', val);
    }

    private get parsedValue(): any {
        if (this.meta.item.type === 'boolean') {
            if (this.meta.value === null) {
                return null;
            }

            if (this.meta.item.extra.includes('required')) {
                if (
                    this.meta.value === 'false' ||
                    this.meta.value === '0' ||
                    !this.meta.value
                ) {
                    return 'false';
                }

                return 'true';
            }

            return (
                this.meta.value !== 'false' &&
                this.meta.value !== '0' &&
                !!this.meta.value
            );
        }

        if (this.meta.item.type === 'values') {
            if (typeof this.meta.value === 'string') {
                this.meta.value = this.meta.value.length
                    ? this.meta.value.split(',')
                    : [];
            }
        }

        return this.meta.value;
    }

    private set parsedValue(val: any) {
        if (this.meta.item.type === 'boolean') {
            if (val === null) {
                this.meta.value = null;
            } else if (val === 'true') {
                this.meta.value = true;
            } else if (val === 'false' || val === '0') {
                this.meta.value = false;
            } else {
                this.meta.value = !!val;
            }
        } else {
            this.meta.value = val;
        }
    }

    get label(): string | undefined {
        if (!this.showName) {
            return undefined;
        }

        if (this.type === 'checkbox') {
            return undefined;
        }

        return this.translatedName;
    }

    get description(): string | undefined {
        if (!this.meta.item.shop_description) {
            return undefined;
        }

        return this.translateValueWithComputedSlug(
            this.meta.item.shop_description,

            // TODO Composing a slug is something we'd rather not do.
            //  Find some way to do this differently (probably map hardcoded values, or have full slug in the description...)
            (value: string) => `shop.common.metaDataDescription.${value}`
        );
    }

    get translatedName(): string {
        return this.translateValueWithComputedSlug(
            this.meta.item.name,

            // TODO Composing a slug is something we'd rather not do.
            //  Find some way to do this differently (probably map hardcoded values, or have full slug in the name...)
            (value: string) => `shop.common.metaData.${value}`
        );
    }

    translateValueWithComputedSlug(
        value: string,
        slugFn: (value: string) => string
    ): string {
        let translatedValue: string = this.$t(value) as string;

        if (translatedValue !== value) {
            return translatedValue;
        }

        const slug = slugFn(value);

        translatedValue = this.$t(slug) as string;

        if (translatedValue !== slug) {
            return translatedValue;
        }

        return value;
    }

    validate(metadata: BaseMetadataItem): void {
        let validator!: MetaInputValidator;
        if (this.$shop && this.$shop.cart) {
            validator = this.$shop.cart.validator.metadata;
        } else if (this.$order) {
            validator = this.$order.validator.metadata;
        } else {
            throw Error('No validator found');
        }

        validator(metadata, true);
    }

    // Map the metadata type to specific components
    get type(): string {
        switch (this.meta.item.type) {
            case 'enum':
                return 'select';
            case 'values':
                return 'select';
            case 'enumOther':
                return 'select-other';
            case 'boolean':
                if (this.meta.item.extra.includes('required')) {
                    return 'selectbar';
                }
                return 'checkbox';
            case 'string':
                if (this.meta.item.extra.includes('email')) {
                    return 'email';
                }
                return 'text';
            case 'phone':
                return 'phone';
            case 'integer':
                return 'integer';
            case 'date':
                return 'date-alt';
            default:
                throw Error(
                    `Metadata type ${this.meta.item.type} is not supported`
                );
        }
    }

    // Return the basic props for this metadata item
    get props(): { [key: string]: any } {
        return {
            name: this.meta.item.name,
            label: this.meta.item.translateName,
            errors: this.meta.errors,
            ...this.typeProps,
        };
    }

    // Return type specific props
    get typeProps(): { [key: string]: any } {
        const type = this.meta.item.type;

        if (type === 'enum' || type === 'enumOther' || type === 'values') {
            let options = '';
            for (const rule of this.meta.item.extra) {
                if (rule.startsWith('in:')) {
                    options = rule.replace('in:', '');
                }
            }

            const map: { [key: string]: string } = {};
            options.split(',').forEach((key: string) => {
                map[key] = key;

                // TODO Composing a slug is something we'd rather not do.
                //  Find some way to do this differently (probably map hardcoded values, or have full slug in the name...)
                const slug = `shop.common.metaData_options.${this.meta.item.name}.${key}`;
                const translateValue: string = this.$t(slug) as string;
                if (translateValue === slug) {
                    map[key] = key;
                } else {
                    map[key] = translateValue as string;
                }
            });

            return {
                options: map,
                otherLabel: this.$t(
                    'shop.common.metaData_options.enumOther.other'
                ),
                multiple: type === 'values',
            };
        } else if (type === 'boolean') {
            return {
                label: this.meta.item.translateName,
                options: {
                    false: this.$t(
                        'shop.common.metaData_options.boolean.false'
                    ) as string,
                    true: this.$t(
                        'shop.common.metaData_options.boolean.true'
                    ) as string,
                },
            };
        }

        return {};
    }
}
