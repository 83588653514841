import Vue from 'vue';
import MetaInput from './MetaInput.vue';
import SplashScreen from './SplashScreen.vue';
import Footer from './Footer.vue';
import BrandedHeader from './BrandedHeader.vue';
import Collapse from './Collapse.vue';
import Logo from './Logo.vue';
import Spinner from './Spinner.vue';

// GENERAL COMPONENTS
Vue.component('BrandedHeader', BrandedHeader);
Vue.component('SplashScreen', SplashScreen);
Vue.component('Footer', Footer);
Vue.component('Collapse', Collapse);
Vue.component('MetaInput', MetaInput);
Vue.component('Logo', Logo);
Vue.component('Spinner', Spinner);
