





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import type { OtLocaleSwitch } from '@openticket/vue-localization';

@Component
export default class Footer extends Vue {
    @Prop({ type: Boolean })
    dark!: boolean;

    @Ref('ot-locale-switch')
    otLocaleSwitch!: OtLocaleSwitch;

    get whitelabelWebsiteUrl(): string {
        return this.$whitelabel.website_url;
    }
}
