
























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class DateBlock extends Vue {
    @Prop() date!: Date;
    @Prop() size!: undefined | 'is-small';
    @Prop() color!: string;

    get day(): string {
        return this.date.getDate().toString();
    }

    get month(): string {
        return this.$localization.locale.monthNamesAbbr[this.date.getMonth()];
    }
}
