import {
    OpenTicketLocalizationVue,
    NestedTranslations,
} from '@openticket/vue-localization';

function joinPath(base: string, path = ''): string {
    return `${base.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`;
}

function xhrTranslations<T extends NestedTranslations = NestedTranslations>(
    url: string
): Promise<T | undefined> {
    return new Promise(function(resolve) {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', url);

        xhr.onload = function() {
            if (this.status >= 200 && this.status < 300) {
                try {
                    resolve(JSON.parse(xhr.response));

                    return;
                } catch (e) {
                    /* No-op */
                }
            }

            resolve(undefined);
        };

        xhr.onerror = function() {
            resolve(undefined);
        };

        xhr.send();
    });
}

/**
 *  Get custom translations if applicable
 */
export async function initCustomTranslations(
    localization: OpenTicketLocalizationVue,
    shopId: string
): Promise<void> {
    const url: string | undefined =
        process.env.VUE_APP_SHOP_CUSTOM_TRANSLATIONS_URL;

    if (!url) {
        return;
    }

    try {
        const customTranslations:
            | { [locale: string]: NestedTranslations }
            | undefined = await xhrTranslations<{
            [locale: string]: NestedTranslations;
        }>(joinPath(url, `/${shopId}/translations.json`));

        if (!customTranslations) {
            return;
        }

        for (const locale in customTranslations) {
            if (
                !Object.prototype.hasOwnProperty.call(
                    customTranslations,
                    locale
                )
            ) {
                continue;
            }

            localization.addCustomMessages(locale, customTranslations[locale]);
        }
    } catch (e) {
        /* No-op */
    }
}
