import Style from '@openticket/lib-style';
import { ModalProgrammatic } from 'buefy';
import { BModalConfig, BModalComponent } from 'buefy/types/components';

export default function openModal(options: BModalConfig): void {
    const width = new Style({}).get('layout-width');

    // eslint-disable-next-line prefer-const
    let el!: BModalComponent;

    const defaults: BModalConfig = {
        width,
        animation: 'fade',
        scroll: 'clip',
        canCancel: false,
        events: {
            close: () => {
                el.close();
            },
        },
    };

    el = ModalProgrammatic.open({
        ...defaults,
        ...options,
    });
}
